import {
  ActionIcon,
  Badge,
  Button,
  Center,
  Checkbox,
  CopyButton,
  Divider,
  Flex,
  Group,
  Indicator,
  NumberInput,
  Radio,
  ScrollArea,
  Stack,
  Text,
  TextInput,
  Textarea,
  Title,
  Tooltip,
  rem,
} from "@mantine/core";
import { useListState, useScrollIntoView } from "@mantine/hooks";
import { IconCheck, IconClearAll, IconCopy } from "@tabler/icons-react";
import { useState } from "react";
import "./App.css";
function App() {
  const [input, setInput] = useState("");
  const [prompt, setPrompt] = useState("");
  const [outputs, outputHandler] = useListState();
  const [charLimit, setCharLimit] = useState(20000);
  const [parseLoading, setParseLoading] = useState(false);
  const [mode, setMode] = useState("lines");
  const { scrollIntoView, targetRef } = useScrollIntoView({
    offset: 60,
  });
  function inputHandler(event) {
    setInput(event.target.value);
  }
  function promptHandler(event) {
    if (event.target.value.length + 3 > charLimit) {
      setCharLimit(event.target.value.length + 3);
    }
    setPrompt(event.target.value);
  }
  function checkboxHandler(index, event) {
    outputHandler.setItemProp(index, "checked", event);
  }
  function parseText() {
    setParseLoading(true);
    outputHandler.setState([]);
    let text = "";
    let modifiedPrompt = "";
    if (prompt.length > 0) {
      modifiedPrompt = prompt + "\n\n";
    }
    switch (mode) {
      case "words":
        const words = input
          .trim()
          .split(" ")
          .filter((word) => word.trim() !== "");
        words.forEach((word) => {
          if (
            (text + word + modifiedPrompt).length >= charLimit &&
            text.length > 0
          ) {
            outputHandler.append({
              text: modifiedPrompt + text.trim(),
              checked: false,
            });
            text = "";
          }
          text += word + " ";
        });
        break;
      case "characters":
        for (let i = 0; i < input.length; i++) {
          text += input[i];
          if (text.length + modifiedPrompt.length === charLimit) {
            outputHandler.append({
              text: modifiedPrompt + text,
              checked: false,
            });
            text = "";
          }
        }
        break;
      case "sentences":
        const sentences = input
          .trim()
          .split(/([^\r\n][^!?.^\r\n]*[\n.?!$])/)
          .filter((text) => text.trim() !== "");
        sentences.forEach((sentence) => {
          if (
            (text + sentence + modifiedPrompt).length >= charLimit &&
            text.length > 0
          ) {
            outputHandler.append({
              text: modifiedPrompt + text,
              checked: false,
            });
            text = "";
          }
          text += sentence;
        });
        break;
      case "lines":
        const paragraphs = input
          .trim()
          .split("\n")
          .filter((text) => text.trim() !== "");
          paragraphs.forEach((paragraph) => {
          if (
            (text + paragraph + modifiedPrompt).length >= charLimit &&
            text.length > 0
          ) {
            outputHandler.append({
              text: modifiedPrompt + text,
              checked: false,
            });
            text = "";
          }
          text += paragraph + "\n";
        });
        break;
      default:
        break;
    }
    if (text.trim() !== "") {
      outputHandler.append({
        text: modifiedPrompt + text.trim(),
        checked: false,
      });
    }
    setParseLoading(false);
    scrollIntoView({
      alignment: "start",
    });
  }

  return (
    <Stack w={"100%"}>
      <Stack
        pos={"fixed"}
        style={{ zIndex: 50 }}
        bg={"black"}
        justify={"center"}
        align={"center"}
        w={"100%"}
        p={"sm"}
        gap={5}
      >
        <Text
          component="a"
          href="https://tscburak.dev/"
          size="26px"
          fw={700}
          variant="gradient"
          ta={"center"}
          ff={"heading"}
          gradient={{ from: "white", to: "gray", deg: 180 }}
        >
          tscburak.dev
        </Text>
        <Badge color={"gray"} size="sm">
          Text Parser
        </Badge>
      </Stack>
      <Center
        component={Stack}
        pl={"xl"}
        pr={"xl"}
        pb={"xl"}
        pt={"calc(60px + var(--mantine-spacing-xl))"}
      >
        <Flex
          direction={{ base: "column", md: "row" }}
          gap={"xl"}
          justify={"space-between"}
          w={"100%"}
        >
          <Stack
            pl={{ base: 0, md: "xl" }}
            pr={{ base: 0, md: "xl" }}
            w={{ base: "100%", md: "50%" }}
          >
            <Textarea
              label={"Input"}
              placeholder="Your Text"
              onChange={inputHandler}
              value={input}
              rows={20}
              styles={{
                input: {
                  overflowX: "hidden",
                },
              }}
              rightSectionProps={{
                style: {
                  marginRight: "var(--mantine-spacing-lg)",
                },
              }}
              rightSection={
                <Stack gap={"xs"} h={"100%"} p={4} align="center">
                  <Text pl={6} mt={"auto"} size="xs">
                    {input.length}
                  </Text>
                </Stack>
              }
            ></Textarea>
            <TextInput
              onChange={promptHandler}
              value={prompt}
              label={"Beginning text for each output"}
              placeholder="e.g: Summarize the below text and generate it as markdown"
              description={
                "Text that you want to add at the beginning of each output (optional)"
              }
            />

            <NumberInput
              min={prompt.length === 0 ? 1 : prompt.length + 3}
              label={"Character Limit"}
              description={"Maximum character length for each output"}
              value={charLimit}
              onChange={setCharLimit}
            />
            <Radio.Group
              value={mode}
              onChange={setMode}
              w={"100%"}
              label={"Parse by"}
            >
              <Group mt={5}>
              <Radio value={"lines"} label={"Lines"}></Radio>
                <Radio value={"sentences"} label={"Sentences"}></Radio>
                <Radio value={"words"} label={"Words"}></Radio>
                <Radio value={"characters"} label={"Characters"}></Radio>
              </Group>
            </Radio.Group>
            <Button
              disabled={input.length === 0}
              loading={parseLoading}
              onClick={parseText}
            >
              Parse
            </Button>
          </Stack>
          <Divider visibleFrom="md" orientation="vertical"></Divider>
          <ScrollArea
            style={{
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
            }}
            offsetScrollbars
            h={{ base: "auto", md: "84vh" }}
            w={{ base: "100%", md: "50%" }}
          >
            <Stack
              ref={targetRef}
              pl={{ base: 0, md: "xl" }}
              pr={{ base: 0, md: "xl" }}
            >
              {outputs.length > 0 && (
                <Stack pt={"md"}>
                  <Divider hiddenFrom="md"></Divider>
                  <Flex align={"center"}>
                    <Title order={5}>
                      {outputs.length} Output{outputs.length > 1 && "s"}
                    </Title>
                    <Tooltip label={"Clear All"}>
                      <ActionIcon
                        ml={"auto"}
                        onClick={() => outputHandler.setState([])}
                      >
                        <IconClearAll />
                      </ActionIcon>
                    </Tooltip>
                  </Flex>
                </Stack>
              )}
              {outputs.map((output, index) => (
                <Textarea
                  leftSectionWidth={60}
                  styles={{
                    input: {
                      overflowX: "hidden",
                      backgroundColor:
                        output.text.length > charLimit
                          ? "#ffdede"
                          : output.checked && "#f1f1f1",
                    },
                  }}
                  rows={10}
                  leftSection={
                    <Stack gap={"xs"} h={"100%"} p={4} align="center">
                      <CopyButton value={output.text}>
                        {({ copied, copy }) => (
                          <Tooltip
                            label={copied ? "Copied" : "Copy"}
                            withArrow
                            position="right"
                          >
                            <ActionIcon
                              color={copied ? "teal" : "gray"}
                              variant="subtle"
                              onClick={() => {
                                copy();
                                outputHandler.setItemProp(
                                  index,
                                  "checked",
                                  true
                                );
                              }}
                            >
                              {copied ? (
                                <IconCheck style={{ width: rem(16) }} />
                              ) : (
                                <IconCopy style={{ width: rem(16) }} />
                              )}
                            </ActionIcon>
                          </Tooltip>
                        )}
                      </CopyButton>
                      <Tooltip label={"Mark as Copied"}>
                        <Checkbox
                          style={{
                            stroke: "5px",
                          }}
                          color="gray"
                          onChange={(event) => {
                            checkboxHandler(index, event.target.checked);
                          }}
                          size="xs"
                          checked={output.checked}
                        />
                      </Tooltip>
                      <Text pl={6} mt={"auto"} size="xs">
                        {output.text.length}
                      </Text>
                    </Stack>
                  }
                  readOnly
                  value={output.text}
                  key={"output_" + index}
                />
              ))}
            </Stack>
          </ScrollArea>
        </Flex>
      </Center>
    </Stack>
  );
}

export default App;
